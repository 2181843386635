<template>
    <v-dialog content-class="dialog-imgs-op" persistent @keydown.esc="close"  v-model="bDialogImgsOP"
        :width="screenWidth">
        <div class="content-card-carousel">
            <v-card class="global-container-dialog container-dialog-imgs-op">
                <v-btn @click="close" icon class="global-btn-close">
                    <v-icon> mdi-close </v-icon>
                </v-btn>
                <div v-if="bInitialImages">
                    <card-imgs-o-p :oItem="oItemImgsOP" :bInitialImages="bInitialImages"
                        :bDialogImgsOP="bDialogImgsOP" />
                </div>
                <v-carousel v-else v-model="iCarouselRawMaterialsEdit" hide-delimiter-background :hide-delimiters="true"
                    :show-arrows="true" height="100%">
                    <template v-slot:prev="{ on, attrs }">
                        <span id="prev-carousel" v-show="false" v-bind="attrs" v-on="on"
                            class="material-icons icons-carousel-promotion">
                            arrow_back_ios
                        </span>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <span id="next-carousel" v-show="false" v-bind="attrs" v-on="on"
                            class="material-icons icons-carousel-promotion">
                            arrow_forward_ios
                        </span>
                    </template>
                    <v-carousel-item v-for="(item, i) in aImgsOP" :key="i">
                        <card-imgs-o-p :oItem="item" :bInitialImages="bInitialImages" :bDialogImgsOP="bDialogImgsOP"
                            :iCarouselRawMaterials="iCarouselRawMaterialsEdit" :aInitialImages="aInitialImages" />
                    </v-carousel-item>
                </v-carousel>
            </v-card>
            <v-btn v-if="!bInitialImages" @click="setPrevCarousel" icon class="btn-arrow-left-raw-material">
                <span class="material-icons icon-arrow-left-raw-material">
                    arrow_back_ios
                </span>
            </v-btn>
            <v-btn v-if="!bInitialImages" @click="setNextCarousel" icon class="btn-arrow-right-raw-material">
                <span class="material-icons icon-arrow-right-raw-material">
                    arrow_forward_ios
                </span>
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        bDialogImgsOP: false,
        aImgsOP: Array,
        oItem: Object,
        bInitialImages: Boolean,
        iCarouselRawMaterials: Number
    },
    data() {
        return {
            iCarouselRawMaterialsEdit: 0,
            iCarousel: 0,
            screenWidth: 0,
            oItemImgsOP: {},
            aInitialImages: [],
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            // size to range
            if (window.innerWidth < 599.99) {
                this.screenWidth = 450 + 'px';
                this.iheight = 200;
            } else if (window.innerWidth > 599.99 && window.innerWidth < 959) {
                this.screenWidth = 600 + 'px';
                this.iheight = 300;
            } else {
                this.screenWidth = 800 + 'px';
                this.iheight = 500;
            }

        },
        close() {
            this.$emit("setDialogImgsOP", null, null);
            this.aInitialImages = [];
        },
        setPrevCarousel() {
            document.getElementById("prev-carousel").click();
        },
        setNextCarousel() {
            document.getElementById("next-carousel").click();
        },
    },
    computed: {
        bShowAmount() {
            return (
                this.aShowAmount.filter((e) => e === this.$route.name)
                    .length > 0
            );
        }
    },
    watch: {
        bDialogImgsOP() {
            if (this.bDialogImgsOP) {
                this.oItemImgsOP = this.oItem;
                if (this.bInitialImages) {
                }
                else {
                    this.iCarouselRawMaterialsEdit = this.oItemImgsOP.i;
                };
            }
        },
    }
}
</script>

<style scoped>
.content-card-carousel {
    /* display: flex; */
    justify-content: center;
    position: relative;
    /* text-align: center; */
    text-align: -webkit-center !important;
}

.btn-arrow-left-raw-material {
    position: absolute;
    left: 10px;
    top: 65vh;
    background-color: var(--primary-color-background-btn-arrow) !important;
}

.icon-arrow-left-raw-material {
    color: var(--primary-color-text-arrow-carousel);
    margin-left: 5px;
}

.icon-arrow-right-raw-material {
    color: var(--primary-color-text-arrow-carousel);
    margin-left: 0px;
}

.btn-arrow-right-raw-material {
    position: absolute;
    right: 10px;
    top: 65vh;
    background-color: var(--primary-color-background-btn-arrow) !important;
}

.container-dialog-imgs-op {
    padding: 20px 0px 0px 0px !important;
    width: 95%;
}

.content-carousel {
    margin-top: 20px;
}

.img-op {
    object-fit: fill;
    height: 500px;
    max-height: 500px;
    min-height: 500px;
    width: 100%;
}

.content-empty-carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    max-height: 520px;
    min-height: 520px;
    width: 100%;

}

.content-img-empty-carousel {
    position: absolute;
    width: auto;

    /* background-color: red; */
    /* left: 50%; */
}

.icon-image-empty-carousel {
    color: var(--primary-color-text);
    left: 40%;
    bottom: 16vh;
    font-size: 200px;
}

.txt-empty-carousel {
    color: var(--primary-color-text);
    font-family: "pop-Semibold";
    text-align: center;

}

.text-title {
    margin-bottom: 2px;
    color: var(--primary-color-text-title-detail);
    font-family: "pop-Regular";
    opacity: 1;
    letter-spacing: 0px;
}

.text-information {
    margin-bottom: 5px;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
}

/*#region STYLE INITIAL WEIGHT */
.content-info-img-initial-weight {
    display: flex;
    width: 100%;
    padding: 20px 20px 20px 20px;
}

.content-txt-info-img {
    display: flex;
    align-items: center;
    width: 50%;
}

.txt-info-img {
    font-size: 25px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.content-Initial-weight {
    text-align: end;
    width: 50%;
}

.txt-initial-weight {
    font-size: 20px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.txt-info-initial-weight {
    font-size: 12px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

/*#endregion STYLE INITIAL WEIGHT */


/*#region STYLE RAW MATERIAL  */
.content-info-name-code-raw-material {
    width: 100%;
    padding: 10px 20px 10px 20px;

}

.content-info-all-raw-material {
    padding: 10px 20px 10px 20px;

}

.txt-name-raw-material {
    font-size: 25px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.txt-code-raw-material {
    font-size: 15px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-Regular";
}

.txt-title {
    font-size: 13px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.txt-value {
    font-size: 14px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-Regular";
}

/*#endregion STYLE RAW MATERIAL  */

/*#region MODO RESPONSIVO **/
/*#region XS */
@media (max-width: 599.99px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .img-op {
        object-fit: fill;
        height: 200px;
        max-height: 200px;
        min-height: 200px;
        width: 100%;
    }

    .content-empty-carousel {
        height: 220px;
        max-height: 220px;
        min-height: 220px;
        width: 100%;
    }

    .btn-arrow-left-raw-material {
        position: absolute;
        left: 1vw;
        top: 60vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }

    .icon-arrow-left-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 5px;
    }

    .icon-arrow-right-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 0px;
    }

    .btn-arrow-right-raw-material {
        position: absolute;
        /* right: 350px; */
        right: 1vw;
        top: 60vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }

    .img-op {
        object-fit: fill;
        height: 300px;
        max-height: 300px;
        min-height: 300px;
        width: 100%;
    }

    .content-empty-carousel {
        height: 320px;
        max-height: 320px;
        min-height: 320px;
        width: 100%;
    }

    .btn-arrow-left-raw-material {
        position: absolute;
        left: 1vw;
        top: 45vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }

    .icon-arrow-left-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 5px;
    }

    .icon-arrow-right-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 0px;
    }

    .btn-arrow-right-raw-material {
        position: absolute;
        /* right: 350px; */
        right: 1vw;
        top: 45vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }


}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }

}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO **/
</style>